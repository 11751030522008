import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Error from "../images/icons/error-404.svg"

const NotFoundPage = () => (
  <Layout hideFooter headerBg="bg-beige">
    <Seo title="404: Not found" />
    <section className="py-8 md:py-20 bg-dark min-h-without-header">
      <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
        <Error className="transform lg:scale-110" />
        <div className="mt-10 md:mt-20 flex flex-wrap items-center text-beige -mx-4">
          <div className="w-full md:w-1/2 px-4 md:-x-6">
            <div className="text-h1/m md:text-h1">In unfamiliar territory?</div>
          </div>
          <div className="w-full md:w-1/2 px-4 md:-x-6">
            <div className="text-h3/m md:text-h3 mt-8 md:mt-0">
              Off trail? You've reached destination 404. Let's get you back on
              track!
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
